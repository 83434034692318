import React from 'react'
import { CardProps } from './types'
import classNames from 'classnames'
import CloseIcon from '../../../icons/closeIcon'

const Card = ({
    leftRadius,
    onClose,
    title,
    lightBorder,
    noShadow,
    noBorder,
    children,
    childrenAfter,
    childrenBefore,
    pullChildrenUp,
    isSticky,
    mealSizeSwitcher,
    image,
    noPadding,
    smallPadding = false,
    mediumPadding = false,
    isHeaderHidden,
    className,
}: CardProps) => {
    return (
        <div
            className={classNames(
                'w-full flex flex-col',
                {
                    'lg:rounded-tr-none lg:rounded-br-none': !!image || leftRadius,
                    'border-none': noBorder,
                    'shadow-none': noShadow,
                    shadow: !noShadow,
                    'rounded-10': !leftRadius,
                    'border-[1px] border-color-[rgb(138, 138, 138, 0.2)': lightBorder,
                    'border-[1px] border-dark/20': !lightBorder && !noBorder,
                    'lg:transition-all lg:duration-500 ease h-full lg:h-fit sticky': isSticky,
                    'lg:top-[100px]': isSticky && !isHeaderHidden,
                    'lg:top-[10px]': isSticky && isHeaderHidden,
                },
                className
            )}
        >
            {childrenBefore && (
                <div
                    className={classNames('', {
                        '-mb-5': pullChildrenUp,
                    })}
                >
                    {childrenBefore}
                </div>
            )}
            <div
                className={classNames('h-full z-10 bg-white flex flex-col border-grey/20', {
                    'border-b-[1px]': childrenAfter,
                    'border-t-[1px]': childrenBefore,
                    'rounded-10': !leftRadius,
                    'rounded-tl-10': leftRadius,
                    'lg:max-h-[calc(100vh_-_110px)] ': isSticky,
                })}
            >
                {title && (
                    <div
                        className={classNames(
                            `flex items-center justify-between font-bold text-xl border-b-[1px] border-dark/20 p-5`
                        )}
                    >
                        {title}
                        <div className="flex items-center gap-3">
                            {/*{mealSizeSwitcher}*/}
                            {onClose && (
                                <button
                                    className="lg:hidden btn btn-round w-10 h-10 bg-black flex lg:hover:cursor-pointer [&>svg]:flex-shrink-0"
                                    onClick={onClose}
                                    aria-label="Sluit pop-up"
                                >
                                    <CloseIcon stroke="#fff" />
                                </button>
                            )}
                        </div>
                    </div>
                )}
                {noPadding ? (
                    <>{children}</>
                ) : (
                    <div
                        className={classNames(``, {
                            'p-5': smallPadding,
                            'lg:p-8 p-5': mediumPadding,
                            'lg:p-10 p-5': !smallPadding && !mediumPadding,
                        })}
                    >
                        {children}
                    </div>
                )}
                {image && <div className={'[&>img]:rounded-bl-10 [&>img]:rounded-br-10 [&>img]:h-64'}>{image}</div>}
            </div>
            {childrenAfter && (
                <div
                    className={classNames('relative z-10', {
                        '-mt-1.25 xl:-mt-2.5': pullChildrenUp,
                    })}
                >
                    {childrenAfter}
                </div>
            )}
        </div>
    )
}

export default Card
