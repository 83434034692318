'use client'

import routes from '@lib/routes'
import { useRouter } from 'next/navigation'

const useMealDetailNavigateBack = () => {
    const router = useRouter()

    return () => {
        if (window.history.state && window.history.state.idx > 0) {
            router.back()
        } else {
            router.push(routes.meals)
        }
    }
}

export default useMealDetailNavigateBack
