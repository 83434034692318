'use client'

import React from 'react'
import { useRouter } from 'next/navigation'
import useMealDetailNavigateBack from 'web/src/utils/mealDetailNavigateBack'

const Overlay = ({ onClick, link }: { onClick?: () => void; link?: string }) => {
    const router = useRouter()
    const navigateBack = useMealDetailNavigateBack()

    return (
        <div
            className="bg-dark/60 inset-0 fixed z-[1095]"
            onClick={() => {
                if (onClick) {
                    onClick()
                } else if (link) {
                    router.push(link)
                } else {
                    navigateBack()
                }
            }}
        />
    )
}

export default Overlay
