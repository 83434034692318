'use client'

import classNames from 'classnames'
import Card from '../Atoms/Card'
import ProductList from '../ProductList'
import UpsellBar from '../UpsellBar'
import Button from '../Button'
import { MINIMUM_QUANTITY } from '../../utils/discountLogic'
import ArrowNextIcon from '../../icons/arrowNextIcon'
import LockIcon from '../../icons/lockIcon'
import { getCartTotals } from '../../utils/getCartTotals'
import PaymentLogos from '../../icons/payment'
import React, { useState } from 'react'
import { CartProps } from './types'
import { useStore, VariantInfoProps } from 'web/src/lib/context/store-context'
import { useCart } from 'medusa-react'
import { CartSubscriptionContext } from 'web/src/types/medusa'
import { useHandleBoxChange } from '../../hooks/useHandleBoxChange'
import routes from 'web/src/lib/routes'
import { useAccount } from 'web/src/lib/context/account-context'
import { useRouter } from 'next/navigation'
import getRandomVariants from 'web/src/utils/getRandomVariants'
import { trackAddToCart } from 'web/src/utils/tracking/trackAddToCart'
import { getProductsFromCartByType } from '../../utils/getProductsFromCartByType'
import titleSort from '../../utils/titleSort'
import { ProductProvider } from 'web/src/lib/context/product-context'

const Cart = ({ mobile }: CartProps) => {
    const {
        hideHeader,
        showCart,
        setShowCart,
        mealTypeId,
        updateCartItems,
        allVariants,
        mealsInCart,
        handlePortionChange,
        hasSeenSizeTutorial,
        setHasSeenSizeTutorial,
        isPortionTutorialOpen,
        closePortionTutorial,
        openPortionTutorial,
    } = useStore()
    const { cart } = useCart()
    const { customer, activeSubscription } = useAccount()
    const router = useRouter()
    const cartSubscriptionContext = cart?.context?.subscription as CartSubscriptionContext
    const { isLoading, handleBoxChange } = useHandleBoxChange()
    const [isAddingRandomMeals, setIsAddingRandomMeals] = useState(false)
    const cartMeals = getProductsFromCartByType('meals', mealTypeId, cart?.items)
    const cartSides = getProductsFromCartByType('sides', mealTypeId, cart?.items)

    const addRandomMeals = async () => {
        setIsAddingRandomMeals(true)
        const productVariants = getRandomVariants(mealTypeId, allVariants, 'single', 6)

        await updateCartItems(
            productVariants.map(v => ({
                variantId: v.variant_id,
                quantity: v.quantity,
            })) as VariantInfoProps[],
            undefined,
            undefined,
            cart =>
                cart?.items.forEach(item => {
                    trackAddToCart(
                        item.variant,
                        item?.variant?.product?.handle || '',
                        item?.variant?.product?.title || '',
                        (item?.unit_price / 100).toString(),
                        item?.thumbnail || '',
                    )
                }),
        )

        setIsAddingRandomMeals(false)
    }

    const handleSubmit = async () => {
        if (cart?.items) {
            if (customer) {
                await handleBoxChange(
                    activeSubscription,
                    cart.items.map(item => ({
                        variantId: item.variant_id!,
                        quantity: item.quantity,
                    })) as VariantInfoProps[],
                    cartSubscriptionContext?.billing_interval || activeSubscription?.billing_interval,
                    'single',
                    cartSubscriptionContext?.renewal_at || activeSubscription?.renewal_at,
                    () => {
                        router.push(routes.account)
                        setShowCart(false)
                    },
                    cartSubscriptionContext?.shipping_notice || '',
                    cartSubscriptionContext?.shipping_notice_recurring || false,
                )
            } else {
                router.push(cart?.email ? routes.details : routes.email)
                setShowCart(false)
            }
        }
    }

    return (
        <div
            className={classNames(
                `translate-all transform-gpu ease h-full lg:h-auto duration-500 max-h-full z-[1095] lg:z-[1090] w-full lg:mx-0 fixed lg:sticky bottom-0 lg:flex lg:w-4/12 flex-shrink`,
                {
                    'opacity-0 translate-y-full lg:opacity-100 lg:translate-y-[0]': !showCart,
                },
                {
                    'opacity-100 translate-y-[0] ': showCart,
                },
                { 'lg:hidden': mobile },
                { 'hidden lg:block': !mobile },
            )}
        >
            <Card
                onClose={() => setShowCart(false)}
                noPadding
                isHeaderHidden={hideHeader}
                isSticky
                noBorder
                // mealSizeSwitcher={
                //     <div
                //         onClick={() => {
                //             hasSeenSizeTutorial ? handlePortionChange() : openPortionTutorial()
                //         }}
                //         className="bg-[rgb(234,248,244)] relative rounded-[10px] cursor-pointer shadow-[0px_5px_20px_rgba(0,0,0,0.1)] flex "
                //     >
                //         <div className="z-10 px-[12px] items-center flex h-[40px] min-w-[50px] justify-center">
                //             <OnePersonIcon
                //                 className={classNames(
                //                     `transition-all duration-500 font-bold text-sm leading-[21px]`,
                //                     {
                //                         'fill-[#8A8A8A]': cartSubscriptionContext?.portion_size === 'double',
                //                     },
                //                     {
                //                         'fill-dark': cartSubscriptionContext?.portion_size === 'single',
                //                     }
                //                 )}
                //             />
                //         </div>
                //         {/*<Tooltip.Provider delayDuration={0}>*/}
                //         {/*    <Tooltip.Root>*/}
                //         {/*        <Tooltip.Trigger asChild>*/}
                //         {/*            <div className="z-10 px-[12px] items-center flex h-[40px] min-w-[50px] justify-center">*/}
                //         {/*                <TwoPersonIcon*/}
                //         {/*                    className={classNames(*/}
                //         {/*                        `transition-all duration-500 font-bold text-sm leading-[21px]`,*/}
                //         {/*                        {*/}
                //         {/*                            'fill-[#8A8A8A]':*/}
                //         {/*                                cartSubscriptionContext?.portion_size === 'single',*/}
                //         {/*                        },*/}
                //         {/*                        {*/}
                //         {/*                            'fill-dark': cartSubscriptionContext?.portion_size === 'double',*/}
                //         {/*                        }*/}
                //         {/*                    )}*/}
                //         {/*                />*/}
                //         {/*            </div>*/}
                //         {/*        </Tooltip.Trigger>*/}
                //         {/*        <Tooltip.Content*/}
                //         {/*            className="z-[2000]  hidden lg:block text-[14px] rounded-[4px] leading-[18px] whitespace-nowrap p-[10px] w-fit text-white bg-black TooltipContent"*/}
                //         {/*            sideOffset={5}*/}
                //         {/*        >*/}
                //         {/*            20% korting*/}
                //         {/*            <Tooltip.Arrow width={14} height={8} className="TooltipArrow" />*/}
                //         {/*        </Tooltip.Content>*/}
                //         {/*    </Tooltip.Root>*/}
                //         {/*</Tooltip.Provider>*/}
                //         <div
                //             className={classNames(
                //                 `w-[50%] absolute transition-all duration-500 left-0 h-full rounded-[10px] bg-white`,
                //                 {
                //                     'left-[0px]': cartSubscriptionContext?.portion_size === 'single',
                //                 },
                //                 {
                //                     'left-[50%]': cartSubscriptionContext?.portion_size === 'double',
                //                 }
                //             )}
                //         />
                //     </div>
                // }
                title={'Mijn maaltijdbox'}
            >
                {cart?.items && cart?.items?.length >= 1 ? (
                    <>
                        <div className="p-5 lg:max-h-[480px] flex-shrink-1 overflow-y-auto">
                            <div className="mb-5 flex flex-col gap-[10px]">
                                <span className="font-bold text-[12px] uppercase mr-[12px] text-grey">Gerechten</span>
                                {cartMeals && cartMeals?.length > 0 ? (
                                    cartMeals?.sort(titleSort).map(item => (
                                        <ProductProvider
                                            key={item.id}
                                            product={{ ...item.variant.product, variants: [item.variant] }}
                                            preselectedVariantId={item.variant_id}
                                        >
                                            <ProductList lineItem={item} />
                                        </ProductProvider>
                                    ))
                                ) : (
                                    <p className="text-xs leading-[21px] font-normal text-dark/70">
                                        Je hebt nog geen gerechten gekozen
                                    </p>
                                )}
                            </div>
                            <div className="mb-5 flex flex-col gap-[10px]">
                                <span className="font-bold text-[12px] uppercase mr-[12px] text-grey">{`Extra's`}</span>
                                {cartSides && cartSides?.length > 0 ? (
                                    cartSides?.sort(titleSort).map(item => (
                                        <ProductProvider
                                            key={item.id}
                                            product={{ ...item.variant.product, variants: [item.variant] }}
                                            preselectedVariantId={item.variant_id}
                                        >
                                            <ProductList lineItem={item} />
                                        </ProductProvider>
                                    ))
                                ) : (
                                    <p className="text-xs leading-[21px] font-normal text-dark/70">
                                        {`Je hebt nog geen extra's gekozen`}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="rounded-bl-10 rounded-br-10 px-5 pb-5 flex-shrink-0 w-full bg-white">
                            <UpsellBar inFloatingCta amount={mealsInCart} />
                            <Button
                                aria-label={'Mijn maaltijdbox opslaan'}
                                disabled={mealsInCart < MINIMUM_QUANTITY}
                                loading={isLoading}
                                rightIcon={<ArrowNextIcon />}
                                leftIcon={customer ? null : <LockIcon />}
                                className="btn-icon w-full px-2"
                                onClick={() => handleSubmit()}
                            >
                                {customer
                                    ? 'Mijn maaltijdbox opslaan'
                                    : `Bestel bij de chef - ${getCartTotals(cart, mealTypeId)}`}
                            </Button>
                            {!customer && (
                                <div className="mt-[10px] h-auto flex align-center justify-center">
                                    <PaymentLogos />
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <div className="p-5 lg:max-h-[480px] flex-shrink-1 overflow-y-auto">
                        <p className="mb-5 text-s leading-[21px] font-normal text-dark/70">
                            {`Je hebt nog geen gerechten of extra's gekozen`}
                        </p>
                        <Button
                            loading={isAddingRandomMeals}
                            className="btn-primary w-full px-2"
                            onClick={() => addRandomMeals()}
                        >
                            {`Vul de box met 6 chef's keuzes`}
                        </Button>
                    </div>
                )}
            </Card>
            {/*<PortionSizeWarning />*/}
            {/*<Dialog*/}
            {/*    title={'Kies een portie'}*/}
            {/*    isOpen={isPortionTutorialOpen}*/}
            {/*    onClose={() => {*/}
            {/*        closePortionTutorial()*/}
            {/*        setHasSeenSizeTutorial(true)*/}
            {/*    }}*/}
            {/*    content={*/}
            {/*        <>*/}
            {/*            <div className={'max-w-[800px] gap-6 flex flex-col'}>*/}
            {/*                <p>*/}
            {/*                    Hoeveel mensen ga je blij maken met de plantaardige maaltijden in je mandje? Tip: je*/}
            {/*                    kunt ook grotere porties voor jezelf bestellen. Wie wat bewaart, die heeft wat.*/}
            {/*                </p>*/}
            {/*                <MealSizeTab*/}
            {/*                    value={cartSubscriptionContext?.portion_size || 'single'}*/}
            {/*                    onValueChange={() => {*/}
            {/*                        closePortionTutorial()*/}
            {/*                        handlePortionChange()*/}
            {/*                        setHasSeenSizeTutorial(true)*/}
            {/*                    }}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*        </>*/}
            {/*    }*/}
            {/*/>*/}
        </div>
    )
}

export default Cart
