import { PortionSize, VariantItem, VariantList } from '../types/medusa'

const MIN_INVENTORY_QUANTITY_TO_ORDER = 10

const getRandomVariants = (
    mealTypeId: string,
    allVariants: VariantList,
    portionSize: string,
    quantity: number
): VariantItem[] => {
    let randomVariants: { [p: string]: { quantity: number; price: number; title: string } } = {}

    const filteredMealVariants = Object.keys(allVariants).reduce(
        (acc: { id: string; quantity: number; title: string; price: number }[], productId) => {
            const variant = allVariants[productId]?.[portionSize as PortionSize]
            const mainProduct = allVariants[productId]
            if (
                !variant ||
                allVariants[productId].type_id !== mealTypeId ||
                variant.inventory_quantity < MIN_INVENTORY_QUANTITY_TO_ORDER
            ) {
                return acc
            }

            return [
                ...acc,
                {
                    id: variant.id,
                    quantity: variant.inventory_quantity,
                    title: mainProduct.title,
                    price: mainProduct.unit_price,
                },
            ]
        },
        []
    )

    filteredMealVariants.sort(() => (Math.random() > 0.5 ? 1 : -1))
    let stopNumber = 0
    for (let i = 0; i < quantity; i++) {
        const number = stopNumber === 0 ? 20 : i % filteredMealVariants.length
        const selectedVariant = filteredMealVariants[number]
        if (!selectedVariant) {
            i = -1
            stopNumber = 1
            continue
        }
        randomVariants = {
            ...randomVariants,
            [selectedVariant.id]: {
                quantity: (randomVariants?.[selectedVariant.id]?.quantity || 0) + 1,
                title: selectedVariant.title,
                price: selectedVariant.price,
            },
        }
    }
    return Object.keys(randomVariants).map(variantId => ({
        variant_id: variantId,
        quantity: randomVariants[variantId].quantity,
        title: randomVariants[variantId].title,
        price: randomVariants[variantId].price,
    }))
}

export default getRandomVariants
