import { Cart } from '@medusajs/medusa'
import { getBulkDiscount } from './getBulkDiscount'
import { formatMedusaPrice } from './formatMedusaPrice'

export const getCartTotals = (cart: Omit<Cart, 'refundable_amount' | 'refunded_total'>, mealTypeId: string) => {
    const lineItemsTotals = cart.items.reduce((acc, item) => acc + item.unit_price * item.quantity, 0)
    const shippingTotals = (cart?.shipping_total || 0) + (cart?.shipping_tax_total || 0)
    const mealQuantity = cart.items
        .filter(item => item.variant.product.type_id === mealTypeId)
        .reduce((acc, item) => acc + item.quantity, 0)
    const bulkDiscount = getBulkDiscount(mealQuantity)

    let total = lineItemsTotals + shippingTotals

    if (bulkDiscount) {
        const bulkDiscountPercentage = (100 - bulkDiscount) / 100
        const discountedTotal = Math.round(total * bulkDiscountPercentage)

        total = discountedTotal
    }

    return formatMedusaPrice(total)
}
