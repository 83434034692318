'use client'
import { Cart, Order as MedusaOrder, Customer, LineItem, StoreCompleteCartRes } from '@medusajs/medusa'
import { useMutation } from '@tanstack/react-query'
import { useCart, useMeCustomer } from 'medusa-react'
import { useRouter } from 'next/navigation'
import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { medusaClient } from '../config'
import routes from '@lib/routes'
import { format } from 'date-fns'
import { useStore } from '@lib/context/store-context'
import { Order, Subscription } from '../../types/medusa'
import { handleError } from '@lib/util/handle-error'
import { trackConversion } from '../../utils/tracking/trackConversion'
import { trackPurchase } from '../../utils/tracking/trackPurchase'
import { useBoolean } from 'ui/hooks/useBoolean'

interface AccountContext {
    customer?: Omit<Customer, 'password_hash'>
    retrievingCustomer: boolean
    activeSubscription?: any
    refetchCustomer: () => void
    refetchSubscription: (newCart?: boolean) => void
    handleLogout: () => void
    createAccount: (order: Order) => Promise<void>
    isCreatingAccount: boolean
    getSubscription: () => Promise<Subscription>
    isConflictingProductsWarningOpen: boolean
    closeConflictingProductsWarning: () => void
    openConflictingProductsWarning: () => void
}

const AccountContext = createContext<AccountContext | null>(null)

const handleDeleteSession = () => {
    return medusaClient.auth.deleteSession()
}

export const AccountProvider = ({ children }: PropsWithChildren) => {
    const { updateCartItems, resetCart, checkBeforeChangeBoxProperty, setHasSeenSizeTutorial } = useStore()
    const {
        customer,
        isLoading: retrievingCustomer,
        refetch: refetchCustomer,
        remove,
    } = useMeCustomer({ onError: () => {} })
    const {
        value: isConflictingProductsWarningOpen,
        setFalse: closeConflictingProductsWarning,
        setTrue: openConflictingProductsWarning,
    } = useBoolean(false)
    const [isCreatingAccount, setIsCreatingAccount] = useState(false)
    const router = useRouter()
    const [activeSubscription, setActiveSubscription] = useState<Subscription | null>(null)
    const { cart } = useCart()
    const getSubscription = async () => {
        return (await medusaClient.client.request('GET', `/store/customers/me/subscriptions`).then(data => {
            setActiveSubscription(data.subscriptions?.[0])
            return data.subscriptions?.[0]
        })) as Subscription
    }

    useEffect(() => {
        if (!customer && !retrievingCustomer && location.pathname.includes('account')) {
            router.push(routes.login)
        } else if (customer && !retrievingCustomer) {
            setHasSeenSizeTutorial(true)
        }
    }, [retrievingCustomer])

    const useDeleteSession = useMutation({
        mutationFn: handleDeleteSession,
        mutationKey: ['delete-session'],
    })

    const handleLogout = () => {
        useDeleteSession.mutate(undefined, {
            onSuccess: () => {
                remove()
                router.push('/')
                resetCart()
            },
        })
    }

    const refetchSubscription = async (newCart?: boolean) => {
        // Use the subscription response here, otherwise the value update of activeSubscription is updated too late
        const subscription = await getSubscription()
        if (subscription) {
            const order = subscription?.upcoming_orders?.find((order: Order) => !order?.shipment_at)

            const subscriptionItems = order?.items?.map((item: LineItem) => ({
                variantId: item.variant.id,
                quantity: item.quantity,
            }))

            const hasNoConflictingProducts = checkBeforeChangeBoxProperty(
                subscription?.metadata?.portion_size as string,
                order?.items,
            )

            if (hasNoConflictingProducts) {
                updateCartItems(
                    subscriptionItems,
                    {
                        subscription: {
                            billing_interval: subscription?.billing_interval,
                            renewal_at: format(new Date(subscription?.renewal_at), 'yyyy-MM-dd'),
                            portion_size: subscription?.metadata?.portion_size,
                        },
                        shipping_notice: subscription?.metadata?.shipping_notice
                            ? (subscription.metadata.shipping_notice as string)
                            : '',
                        shipping_notice_recurring: subscription?.metadata?.shipping_notice_recurring
                            ? (subscription.metadata.shipping_notice_recurring as boolean)
                            : false,
                    },
                    !!newCart,
                )
            } else {
                openConflictingProductsWarning()
            }
        } else {
            resetCart()
        }
    }

    // send data to medusa backend to create an account
    const createAccount = async (order: Order) => {
        setIsCreatingAccount(true)

        await medusaClient.client
            .request('POST', `/store/customers/${order.id}/create`)
            .then(async () => {
                sessionStorage.setItem('firstUserSession', 'true')
                await refetchCustomer()
            })
            .catch(e => {
                handleError(e)
            })
            .finally(() => {
                setIsCreatingAccount(false)
            })
    }

    return (
        <AccountContext.Provider
            value={{
                getSubscription,
                customer,
                retrievingCustomer,
                createAccount,
                isCreatingAccount,
                activeSubscription,
                refetchCustomer,
                refetchSubscription,
                handleLogout,
                isConflictingProductsWarningOpen,
                closeConflictingProductsWarning,
                openConflictingProductsWarning,
            }}
        >
            {children}
        </AccountContext.Provider>
    )
}

export const useAccount = () => {
    const context = useContext(AccountContext)

    if (context === null) {
        throw new Error('useAccount must be used within a AccountProvider')
    }

    return context
}
