import { Product } from 'web/src/types/medusa'
import { LineItem } from '@medusajs/medusa'

export const getProductsFromCartByType = (productType: string, mealTypeId: string, cartItems?: LineItem[]) => {
    return cartItems?.reduce((acc: LineItem[], item) => {
        const include =
            productType === 'meals'
                ? (item.variant.product as unknown as Product)?.type_id === mealTypeId
                : (item.variant.product as unknown as Product)?.type_id !== mealTypeId

        if (include) {
            return [...acc, item]
        } else {
            return acc
        }
    }, [])
}
