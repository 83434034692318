import React, { FC, SVGAttributes } from 'react'
import Image from 'next/image'

const paymentMethods = ['ideal', 'maestro', 'amex', 'mastercard', 'visa']

const PaymentLogos = ({ ...props }) => (
    <div className="flex items-center gap-2">
        {paymentMethods.map(paymentMethod => (
            <Image
                className="w-[32px] h-[24px]"
                key={paymentMethod}
                src={`/icons/payment/${paymentMethod}.svg`}
                alt={paymentMethod}
                width={32}
                height={24}
            />
        ))}
    </div>
)

export default PaymentLogos
