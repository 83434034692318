'use client'
import React, { useMemo } from 'react'
import { UpsellBarProps } from './types'
import classNames from 'classnames'
import {
    FREE_SHIPPING_MEALS_THRESHOLD,
    MEAL_BULK_DISCOUNT_THRESHOLDS,
    MINIMUM_QUANTITY,
} from '../../utils/discountLogic'

const UpsellBar = ({ amount, inFloatingCta }: UpsellBarProps) => {
    const shipping_thresh = FREE_SHIPPING_MEALS_THRESHOLD
    const minimumQuantity = MINIMUM_QUANTITY
    const mealThresh = Object.keys(MEAL_BULK_DISCOUNT_THRESHOLDS).map(threshold => {
        return {
            threshold: Number(threshold),
            discount: MEAL_BULK_DISCOUNT_THRESHOLDS[Number(threshold)],
        }
    })

    const {
        minimumPercentage,
        thresholdText,
        shippingPercentage,
        firstThresholdPercentage,
        secondThresholdPercentage,
    } = useMemo(() => {
        if (amount < minimumQuantity) {
            return {
                thresholdText: `Voeg nog minimaal <b>${minimumQuantity - amount} ${
                    minimumQuantity - amount === 1 ? 'gerecht' : 'gerechten'
                }</b> toe`,
                minimumPercentage: (amount / minimumQuantity) * 100,
                shippingPercentage: 0,
                firstThresholdPercentage: 0,
                secondThresholdPercentage: 0,
            }
        } else if (amount < shipping_thresh) {
            return {
                thresholdText: `Nog ${shipping_thresh - amount} ${
                    shipping_thresh - amount === 1 ? 'gerecht' : 'gerechten'
                } erbij en krijg <b>gratis verzending t.w.v. €5.99</b>`,
                minimumPercentage: 100,
                shippingPercentage: ((amount - minimumQuantity) / (shipping_thresh - minimumQuantity)) * 100,
                firstThresholdPercentage: 0,
                secondThresholdPercentage: 0,
            }
        } else if (amount < mealThresh[0].threshold) {
            return {
                amountToThreshold: mealThresh[0].threshold - amount,
                thresholdText: `Nog ${mealThresh[0].threshold - amount} ${
                    mealThresh[0].threshold - amount === 1 ? 'gerecht' : 'gerechten'
                } erbij en krijg <b>${mealThresh[0].discount}% korting</b>`,
                minimumPercentage: 100,
                shippingPercentage: 100,
                firstThresholdPercentage:
                    ((amount - shipping_thresh) / (mealThresh[0].threshold - shipping_thresh)) * 100,
                secondThresholdPercentage: 0,
            }
        } else if (amount < mealThresh[1].threshold) {
            return {
                amountToThreshold: mealThresh[1].threshold - amount,
                thresholdText: `Nog ${mealThresh[1].threshold - amount} ${
                    mealThresh[1].threshold - amount === 1 ? 'gerecht' : 'gerechten'
                } erbij en krijg <b>${mealThresh[1].discount}% korting</b>`,
                minimumPercentage: 100,
                shippingPercentage: 100,
                firstThresholdPercentage: 100,
                secondThresholdPercentage:
                    ((amount - mealThresh[0].threshold) / (mealThresh[1].threshold - mealThresh[0].threshold)) * 100,
            }
        }
        return {
            amountToThreshold: null,
            thresholdText: `Je hebt de <b>maximale korting</b>!`,
            minimumPercentage: 100,
            shippingPercentage: 100,
            firstThresholdPercentage: 100,
            secondThresholdPercentage: 100,
        }
    }, [amount])

    return (
        <div
            className={classNames(
                `pt-[10px] py-[20px] pb-[40px] after:pointer-events-none after:absolute after:bg-grey/10 after:bottom-0 after:top-0 relative w-full h-[88px]"`,
                {
                    'mb-[-45px] lg:after:-left-10 after:-left-5 after:w-[calc(100%+40px)] lg:after:w-[calc(100%+80px)]':
                        !inFloatingCta,
                },
                { 'mb-[-25px] after:py-5 after:-left-5 after:w-[calc(100%+40px)]': !!inFloatingCta }
            )}
        >
            <p
                className="text-dark text-[12px] leading-[21px] text-center"
                dangerouslySetInnerHTML={{ __html: thresholdText }}
            />
            <div className="mt-2 flex gap-2">
                <div className={'z-10 relative w-6/12 h-[8px]'}>
                    <div className="absolute w-full h-[8px] bg-white rounded-10"></div>
                    <div
                        style={{ width: minimumPercentage !== null ? `${minimumPercentage}%` : '100%' }}
                        className={`transition-width duration-300 left-0 absolute h-[8px] bg-green-brand rounded-10`}
                    ></div>
                </div>
                <div className={'z-10 relative w-2/12 h-[8px]'}>
                    <div className="absolute w-full h-[8px] bg-white rounded-10"></div>
                    <div
                        style={{ width: shippingPercentage !== null ? `${shippingPercentage}%` : '100%' }}
                        className={`transition-width duration-300 left-0 absolute h-[8px] bg-green-brand rounded-10`}
                    ></div>
                </div>
                <div className={'z-10 relative w-2/12 h-[8px]'}>
                    <div className="absolute w-full h-[8px] bg-white rounded-10"></div>
                    <div
                        style={{ width: firstThresholdPercentage !== null ? `${firstThresholdPercentage}%` : '100%' }}
                        className={`transition-width duration-300 left-0 absolute h-[8px] bg-green-brand rounded-10`}
                    ></div>
                </div>
                <div className={'z-10 relative w-2/12 h-[8px]'}>
                    <div className="absolute w-full h-[8px] bg-white rounded-10"></div>
                    <div
                        style={{ width: secondThresholdPercentage !== null ? `${secondThresholdPercentage}%` : '100%' }}
                        className={`transition-width duration-300 left-0 absolute h-[8px] bg-green-brand rounded-10`}
                    ></div>
                </div>
            </div>
        </div>
    )
}

export default UpsellBar
