const body = typeof document !== "undefined" ? document.querySelector("body") : null
// let scrollPosition = 0

export const scrollLock = {
    enable() {
        if (body) {
            // scrollPosition = window.pageYOffset
            body.style.overflow = "hidden"
            // body.style.position = 'fixed'
            // body.style.top = `-${scrollPosition}px`
            // body.style.left = '0'
            // body.style.right = '0'
        }
    },
    disable() {
        if (body) {
            body.style.removeProperty("overflow")
            // body.style.removeProperty('position')
            // body.style.removeProperty('top')
            // body.style.removeProperty('left')
            // body.style.removeProperty('right')
            // window.scrollTo(0, scrollPosition)
        }
    }
}
