import { MEAL_BULK_DISCOUNT_THRESHOLDS } from './discountLogic'

export const getBulkDiscount = (mealQuantity: number): null | number => {
    const bulkDiscountList = MEAL_BULK_DISCOUNT_THRESHOLDS
    let bulkDiscount = null

    // If there is a bulk discount set in the config, check which discount should be applied
    if (bulkDiscountList) {
        Object.keys(bulkDiscountList).forEach(discountQuantity => {
            if (mealQuantity >= parseInt(discountQuantity)) {
                bulkDiscount = bulkDiscountList[parseInt(discountQuantity)]
            }
        })
    }

    return bulkDiscount
}
