import React, { FC, SVGAttributes } from 'react'

const LockIcon: FC<SVGAttributes<SVGElement>> = ({ ...props }) => (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.83093 0.25C5.67805 0.25 4.57238 0.710936 3.75717 1.53141C2.94196 2.35188 2.48397 3.46468 2.48397 4.625V7.125C1.82518 7.125 1.19337 7.38839 0.727539 7.85723C0.261704 8.32607 0 8.96196 0 9.625V15.25C0 15.913 0.261704 16.5489 0.727539 17.0178C1.19337 17.4866 1.82518 17.75 2.48397 17.75H11.1779C11.8367 17.75 12.4685 17.4866 12.9343 17.0178C13.4002 16.5489 13.6619 15.913 13.6619 15.25V9.625C13.6619 8.96196 13.4002 8.32607 12.9343 7.85723C12.4685 7.38839 11.8367 7.125 11.1779 7.125V4.625C11.1779 2.20833 9.2321 0.25 6.83093 0.25ZM9.9359 7.125V4.625C9.9359 3.7962 9.60877 3.00134 9.02647 2.41529C8.44418 1.82924 7.65442 1.5 6.83093 1.5C6.00744 1.5 5.21768 1.82924 4.63539 2.41529C4.05309 3.00134 3.72596 3.7962 3.72596 4.625V7.125H9.9359Z"
            fill="white"
        />
    </svg>
)

export default LockIcon
